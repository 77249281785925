var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: { size: "default", type: "primary", icon: "el-icon-plus" },
          on: {
            click: function($event) {
              _vm.dialogVisible = true
            }
          }
        },
        [_vm._v("Organisation erstellen")]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "ta-l",
          attrs: {
            title: "Organisation erstellen",
            visible: _vm.dialogVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-steps",
            {
              staticClass: "m-b-3",
              attrs: {
                active: _vm.activeStep,
                "align-center": "",
                "finish-status": "success"
              }
            },
            [
              _c("el-step", { attrs: { title: "Basics" } }),
              _c("el-step", { attrs: { title: "Admin" } })
            ],
            1
          ),
          _vm.activeStep == 0
            ? _c(
                "div",
                [
                  _c("AddOrganisationBasicInformation", {
                    attrs: {
                      data: {
                        name: _vm.data.name
                      }
                    },
                    on: {
                      nextStep: function($event) {
                        return _vm.nextStep($event)
                      },
                      cancel: function($event) {
                        return _vm.cancelCreation()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.activeStep == 1
            ? _c(
                "div",
                [
                  _c("AddOrganisationAdmin", {
                    attrs: {
                      currentMembers: _vm.currentMemberList,
                      data: { admin: _vm.data.admin },
                      isLoading: _vm.isLoading
                    },
                    on: {
                      nextStep: _vm.nextStep,
                      back: function($event) {
                        _vm.activeStep--
                      },
                      updateData: function($event) {
                        return _vm.updateData($event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.activeStep == 2
            ? _c("div", [
                _c("p", [
                  _vm._v("Admin Passwort: " + _vm._s(_vm.adminPassword))
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }