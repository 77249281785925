var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    this.organisationsArray !== "undefined" &&
    this.organisationsArray.length > 0
      ? _c(
          "div",
          [
            _c(
              "el-table",
              {
                ref: "filterTable",
                staticStyle: { width: "100%" },
                attrs: { data: _vm.organisationsArray }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: "Name", width: "180" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "numberOfMembers",
                    label: "Teamgröße",
                    width: "100"
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "isActive", label: "Status", width: "100" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type:
                                    _vm.showAktivStatus(scope.row.id)
                                      .isActive == true
                                      ? "success"
                                      : "danger",
                                  "disable-transitions": ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.showAktivStatus(scope.row.id).status
                                  ) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3703758201
                  )
                }),
                _c("el-table-column", {
                  attrs: { align: "right", label: "Aktionen" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button-group",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-edit"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "OrganisationDetail",
                                          params: {
                                            id: scope.row.id,
                                            name: scope.row.name
                                          }
                                        })
                                      }
                                    }
                                  },
                                  [_vm._v(" Bearbeiten ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      icon: "el-icon-delete",
                                      plain: "",
                                      loading: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDeleteDialogue(
                                          scope.row.id
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Löschen ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    916894655
                  )
                })
              ],
              1
            )
          ],
          1
        )
      : _c("div", [_c("h3", [_vm._v(" Keine Organisationen angelegt")])])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }