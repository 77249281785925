var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "Main" },
    [
      _c(
        "el-main",
        [
          _c(
            "el-card",
            { staticClass: "box-card" },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("../assets/logo.png"), width: "175" }
              }),
              _c("p", { staticClass: "m-b-4" }, [
                _vm._v("Administrator-Login.")
              ]),
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { rules: _vm.rules, model: _vm.form },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onSubmit("form")
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "email" } },
                    [
                      _c("el-input", {
                        attrs: { type: "email", placeholder: "E-Mail" },
                        model: {
                          value: _vm.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "m-b-1", attrs: { prop: "password" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "Passwort", "show-password": "" },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "stayLoggedIn" },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.form.stayLoggedIn,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "stayLoggedIn", $$v)
                            },
                            expression: "form.stayLoggedIn"
                          }
                        },
                        [_vm._v("Angemeldet bleiben?")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "loginButton" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", loading: _vm.isLoading },
                          on: {
                            click: function($event) {
                              return _vm.onSubmit("form")
                            }
                          }
                        },
                        [_vm._v("Login")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }