var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loadingWrapper" },
    [
      _c("scale-loader", { attrs: { loading: "loading", color: "#122c51" } }),
      _c("h4", { staticClass: "m-t-3" }, [_vm._v("Einen Moment bitte")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }