var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.loadingState
        ? _c("Loading")
        : _c(
            _vm.layout,
            { tag: "component" },
            [
              _c(
                "vue-page-transition",
                { attrs: { name: "fade" } },
                [_c("router-view", { staticClass: "appContentWrapper" })],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }