<!-- Loading-Screen tamplate -->
<template>
  <div class="loadingWrapper">
    <scale-loader loading="loading" color="#122c51"></scale-loader>
    <h4 class="m-t-3">Einen Moment bitte</h4>
  </div>
</template>

<script>
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
export default {
  components: {
    ScaleLoader
  }
};
</script>

<style lang="scss">
@import "../styles/variables";
.loadingWrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);

  h4 {
    color: $accent-gray;
  }
}
</style>
