<template>
  <el-container class="Main">
    <el-main>
      <el-card class="box-card">
        <img class="logo" src="../assets/logo.png" width="175" />
        <p class="m-b-4">Administrator-Login.</p>

        <!-- Login Form -->
        <el-form :rules="rules" ref="form" :model="form" @keyup.enter.native="onSubmit('form')">
          <el-form-item prop="email">
            <el-input type="email" placeholder="E-Mail" v-model="form.email" />
          </el-form-item>
          <el-form-item class="m-b-1" prop="password">
            <el-input placeholder="Passwort" v-model="form.password" show-password />
          </el-form-item>
          <el-form-item class="stayLoggedIn">
            <el-checkbox v-model="form.stayLoggedIn">Angemeldet bleiben?</el-checkbox>
          </el-form-item>
          <el-form-item class="loginButton">
            <el-button type="primary" @click="onSubmit('form')" :loading="isLoading">Login</el-button>
          </el-form-item>
        </el-form>

      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import firebase from "firebase";
export default {
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        password: "",
        stayLoggedIn: false
      },
      rules: {
        email: [
          {
            required: true,
            trigger: "blur",
            message: "Dieses Feld darf nicht leer sein"
          },
          {
            type: "email",
            trigger: "blur",
            message: "Du musst eine gültige E-Mail eingeben"
          }
        ],
        password: [
          { required: true, message: "Dieses Feld darf nicht leer sein" }
        ]
      }
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.isLoading = true;
          try {
            // Change persistence type
            if (!this.form.stayLoggedIn) {
              await firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.SESSION);
            } else {
              await firebase
                .auth()
                .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
            }

            // Sign in user
            await firebase
              .auth()
              .signInWithEmailAndPassword(this.form.email, this.form.password);
            this.isLoading = false;
          } catch (e) {
            console.log(e);
            this.$message({
              showClose: true,
              message: "Deine Anmeldedaten sind leider nicht korrekt.",
              type: "warning"
            });
            this.isLoading = false;
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.el-card {
  width: 350px;
  padding: 30px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
  position: relative;
  overflow: visible;
}

.stayLoggedIn {
  text-align: left;
}

.loginButton {
  text-align: right;
  margin-bottom: 0px !important;
}

.el-card::after {
  position: absolute;
  background: #122c51;
  top: -7px;
  border-radius: 5px;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -5;
}
</style>
