<template>
  <div>
    <div v-if="this.organisationsArray !== 'undefined' && this.organisationsArray.length > 0">

    <!-- Table with all available organisations -->
    <el-table
      ref="filterTable"
      :data="organisationsArray"
      style="width: 100%">

      <el-table-column
        prop="name"
        label="Name"
        width="180">
      </el-table-column>

      <el-table-column
        prop="numberOfMembers"
        label="Teamgröße"
        width="100"> 
      </el-table-column>

      <!-- isActive indicator -->
      <el-table-column
        prop="isActive"
        label="Status"
        width="100">
        <template slot-scope="scope">
          <el-tag
            :type="showAktivStatus(scope.row.id).isActive == true ? 'success' : 'danger'"
            disable-transitions>{{showAktivStatus(scope.row.id).status}}
          </el-tag>
        </template>
      </el-table-column>

      <!-- Actions -->
      <el-table-column align = "right" label = "Aktionen">
        <template slot-scope = "scope"> 
          <el-button-group>
            <el-button 
              type="primary" 
              icon="el-icon-edit"
              @click="$router.push({ name: 'OrganisationDetail', params: { id: scope.row.id, name: scope.row.name} })">
                Bearbeiten
            </el-button>
            <el-button 
              type="danger" 
              icon="el-icon-delete" plain
              @click="openDeleteDialogue(scope.row.id)"
              :loading="isLoading">
                Löschen
              </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    </div>

    <!-- Placeholder, if organisationlist is empty -->
    <div v-else>
      <h3> Keine Organisationen angelegt</h3>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    data() {
      return {
        isLoading: false
      }
    },

  computed: {
    ...mapState({ organisations: state => state.organisations.list }),
    
    // Transform organisations-object to organisations-array
    organisationsArray() {
      let organisationsArray = [];
      Object.keys(this.organisations).forEach(id => {
        organisationsArray.push({
          id: id,
          ...this.organisations[id] 
        });
      });
      return organisationsArray;
    }
  },

  created() {
    // Updating organisaiton-list
    this.$store.dispatch("updateOrganisationList");
  },

  methods: {
    // Delete dialogue to prevent accidential deletion
    openDeleteDialogue(organisationId) {
      this.$confirm('Organisation wirklich endgültig löschen?', 'Warnung', {
        confirmButtonText: 'Löschen',
        cancelButtonText: 'Abbrechen',
        type: 'warning'
      }).then(() => {
        this.deleteOrganisation(organisationId);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Löschen abgebrochen'
        });          
      });
    },

    // Delete the selected organisation, identified by parameter
    async deleteOrganisation(organisationId) {
      this.isLoading = true;
      this.$store.dispatch("deleteOrganisation", {organisationId: organisationId});
      this.$message({
          type: 'success',
          message: 'Löschen abgeschlossen'})
      this.isLoading = false;
    },

    // Return organisations status (identified by param.) as object including bool and string
    showAktivStatus(index) {
      const found = this.organisationsArray.find(element => element.id == index);
      if(found.isActive == true){
        return { isActive: true, status: "AKTIV"}
      }
      else{
        return { isActive: false, status: "INAKTIV"}
      }
    }
  }
};
</script>