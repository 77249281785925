<!-- Top-Side Header -->
<template>
  <el-header height="auto">
    <el-row :gutter="20" align="middle" type="flex">
      <el-col :span="12">
        <h5>ADMIN Interface</h5>
        <h2>Verwaltungszentrale</h2>
      </el-col>
      <el-col :span="12" style="text-align: right;"></el-col>
    </el-row>
    <div class="m-t-2">
      <el-button
        v-for="(item, index) in subItems"
        v-bind:key="index"
        v-bind:class="{ active: item.isActive }"
        @click="$router.push({ name: item.name }) .catch(err => {})"
        type="text"
      >{{ item.title }}</el-button>
    </div>
  </el-header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      subItems: Array,
      title: ""
    };
  },
  watch: {
    $route() {
      this.updateHeaderData();
    }
  },
  mounted() {
    this.updateHeaderData();
  },
  methods: {
    updateHeaderData() {
      this.resetHeaderData();
      let currentRoute = this.$route;

      // Get name of route through meta
      this.title = currentRoute.meta.title;

      if (currentRoute.matched.length > 1) {
        this.subItems = this.getSubItems(currentRoute);
      }
    },
    resetHeaderData() {
      this.title = "";
      this.subItems = [];
      this.activeLink = "";
    },
    getSubItems(currentRoute) {
      // Get a list of subItems that are valid for that route
      let allRoutes = this.$router.options.routes;
      let highestRoute = currentRoute.matched[0];

      // Get matching parent from Router
      let children = allRoutes.filter(r => r.path == highestRoute.path)[0]
        .children;

      let validChildren = [];

      // Only get childs that are not hidden & have a title
      children.forEach(r => {
        if (r.meta && (!r.meta.hideNav && r.meta.title != "")) {
          // Route should be part of subitems
          // Check if subItem should be active
          let subItem = {};

          if (currentRoute.name == r.name) {
            subItem.isActive = true;
          }
          subItem.title = r.meta.title;
          subItem.name = r.name;

          validChildren.push(subItem);
        }
      });

      return validChildren;
    }
  }
};
</script>

<style lang="scss" scoped>
// Styling of header
.el-header {
  background: white;
  padding: 10px 30px;
  padding-bottom: 0px;
  border-bottom: 1px solid #f3f3f3;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 3px 15px;

  h2 {
    margin-top: 5px;
  }
  h5 {
    margin-bottom: 0px;
  }
}
.el-avatar {
  border: 1px solid #f3f3f3;
}
.el-button {
  margin-right: 20px;
  &.active {
    border-bottom: rgb(5, 108, 243) solid 3px;
    border-radius: 0;
  }
}
</style>
