<!-- Overview Component -->
<template>
  <div>
     <div class="ta-r m-b-2">
      <CreateOrganisation style="margin-left: auto;" />
    </div> 
    <h2 class="m-b-3"></h2>
      <ul id="example-1">
        <OrganisationList></OrganisationList>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrganisationList from "@/components/OrganisationList"
import CreateOrganisation from "@/views/organisation/OrganisationCreate/";

export default {
  components: { 
    OrganisationList,
    CreateOrganisation
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss"></style>
