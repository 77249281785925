import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/";
import "./registerServiceWorker";
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";
import firebaseEnvironments from "./firebaseEnvironments";

// Layouts 
import Default from "./layouts/Default"
import Blank from "./layouts/Blank";

// Element UI
// Element UI Imports
import ElementUI from "element-ui";
import "./styles/index.css";
import "./styles/styles.scss";
import locale from "element-ui/lib/locale/lang/en";
import VuePageTransition from 'vue-page-transition'


// Use Element UI Framework
// Vue.use(ElementUI);
Vue.use(ElementUI, { locale });
Vue.use(VuePageTransition)

// Firebase
let firebaseConfig = {
  apiKey: process.env.VUE_APP_F_API_KEY,
  authDomain: process.env.VUE_APP_F_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_F_DATABASE_URL,
  projectId: process.env.VUE_APP_F_PROJECT_ID,
  storageBucket: process.env.VUE_APP_F_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_F_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_F_APP_ID,
  measurementId: process.env.VUE_APP_F_MEASUREMENT_ID
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = app.firestore();
export const functions = app.functions("europe-west1");
export const analytics = app.analytics();
export const performance = app.performance();

// if dev mode, test with local cloud functions server
if (process.env.NODE_ENV === "development") {

  db.settings({
    host: "localhost:8081",
    ssl: false
  });

  functions.useFunctionsEmulator("http://localhost:5000");
}
//Bind firebase to Vue instance
Vue.prototype.$firebase = firebase;

// Vue
// Load Templates for views
Vue.component('default-layout', Default);
Vue.component('blank-layout', Blank);

// Vue specific config
Vue.config.productionTip = false;

// Create vue instance
new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // Check auth state of local storage
    let authState = localStorage.auth;
    if (authState == "true") {
      // Set initial loading state to true & show loading
      // screen to gather initial data
      store.dispatch("updateInitialLoadingState", true);

    }
  }
}).$mount("#app");

// Handle the change of auth state
firebase.auth().onAuthStateChanged(async function (user) {
  if (user) {
    let tokens = await firebase.auth().currentUser.getIdTokenResult();
    let role = tokens.claims.role;

    if (role == "root") {
      // Local Storage to render correct router quicker
      localStorage.auth = true;

      // Reset loading state after data is gathered
      store.dispatch("updateInitialLoadingState", false);

      // Redirect if necesarry
      if (router.currentRoute.name == "Login") {
        router.replace({ name: "Dashboard" });
      }
    } else {
      firebase.auth().signOut();
    }

  } else {
    store.commit("setEmail", null);
    store.commit("setUid", null);
    store.commit("setAuthState", false);
    localStorage.auth = false;
    if (
      router.currentRoute.name != "Voting" &&
      router.currentRoute.name != "Login"
    ) {
      router.replace({ name: "Login" });
    }
    store.dispatch("updateInitialLoadingState", false);
  }
});
