var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-header",
    { attrs: { height: "auto" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20, align: "middle", type: "flex" } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("h5", [_vm._v("ADMIN Interface")]),
            _c("h2", [_vm._v("Verwaltungszentrale")])
          ]),
          _c("el-col", {
            staticStyle: { "text-align": "right" },
            attrs: { span: 12 }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "m-t-2" },
        _vm._l(_vm.subItems, function(item, index) {
          return _c(
            "el-button",
            {
              key: index,
              class: { active: item.isActive },
              attrs: { type: "text" },
              on: {
                click: function($event) {
                  _vm.$router.push({ name: item.name }).catch(function(err) {})
                }
              }
            },
            [_vm._v(_vm._s(item.title))]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }