import Vue from "vue";
import Vuex from "vuex";
import api from "./modules/api";
import organisations from "./modules/organisations";
import surveys from "./modules/surveys";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    api,
    organisations,
    surveys
  },
  strict: process.env.NODE_ENV !== "production"
});
