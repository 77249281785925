var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "ta-r m-b-2" },
      [_c("CreateOrganisation", { staticStyle: { "margin-left": "auto" } })],
      1
    ),
    _c("h2", { staticClass: "m-b-3" }),
    _c("ul", { attrs: { id: "example-1" } }, [_c("OrganisationList")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }