<template>
  <div id="app">
    <Loading v-if="loadingState" />
    <component v-else :is="layout">
      <vue-page-transition name="fade">
        <router-view class="appContentWrapper" />
      </vue-page-transition>
    </component>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "./views/Loading";
import Navigation from "./components/Navigation";
export default {
  components: {
    Loading,
    Navigation
  },
  computed: {
    ...mapState({ loadingState: state => state.api.initialLoad }),
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    }
  }
};
</script>

<style lang="scss">
.appContentWrapper {
  position: relative;
}
.contentWrapper {
  margin-left: 200px;
}
</style>
