<!-- Left-Side-Bar Navigation -->
<template>
  <el-aside width="200px">
    <el-menu
      :default-active="activeLink"
      :collapse="false"
      :router="true"
      text-color="#eee"
      active-text-color="#fff"
      background-color="#122c51"
    >
      <img class="iconTop" src="../assets/icon_white.png" width="35px" />
      <div class="menuItemWrapper">
        <el-menu-item
          index="dashboard"
          @click="$router.push({ name: 'Dashboard' }).catch(err => {})"
        >
          <i class="el-icon-user"></i>
          <span slot="title">Organisationen</span>
        </el-menu-item>
        <el-menu-item
          index="configuration"
          @click="$router.push({ name: 'Configuration' }).catch(err => {})"
        >
          <i class="el-icon-user"></i>
          <span slot="title">Configuration</span>
        </el-menu-item>
      </div>
      <el-dropdown class="collapseButton" @command="handleCommand" trigger="click">
        <el-avatar
          class="el-dropdown-link"
          src="https://www.xing.com/image/7_c_b_bcb2f14ee_32712290_1/benedikt-b%C3%B6ringer-foto.1024x1024.jpg"
        ></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>Einstellungen</el-dropdown-item>
          <el-dropdown-item command="logout" divided>Ausloggen</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-menu>
    <div></div>
  </el-aside>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      activeLink: null
    };
  },
  watch: {
    $route(to) {
      this.activeLink = to.path;
    }
  },
  mounted: function() {
    this.activeLink = this.$route.path;
  },
  methods: {
    async handleCommand(command) {
      if (command == "logout") {
        try {
          await this.$firebase.auth().signOut();
          // signed out
        } catch (e) {
          // an error
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables";
// Styling of menu
.el-aside {
  height: 100%;
  position: fixed;
}
.collapseButton {
  position: absolute;
  bottom: 25px;
  left: 10px;
}
.el-menu {
  height: 100%;
}
.menuItemWrapper {
  top: 50%;
  position: relative;
  transform: translateY(-100%);
}
.iconTop {
  margin-left: 15px;
  margin-top: 25px;
}
</style>
