import Vue from "vue";
import Router from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";
import Organisation from "@/views/organisation";
import Configuration from "@/views/configuration";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
      meta: {
        layout: "blank"
      },
      beforeEnter: (to, from, next) => {
        // Redirect automatically when authenticated
        if (localStorage.auth == "true") {
          next({
            path: "/dashboard"
          });
        } else {
          next();
        }
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
        title: "Dashboard"
      }
    },
    {
      path: "/organisations",
      component: Organisation,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "Organisations",
          redirect: { name: "Organisations" }
        }
      ]
    },
    {
      path: "/organisation/:id/:name",
      name: "OrganisationDetail",
      title: "OrganisationDetail",
      component: () =>
        import(/* webpackChunkName: "CustomerDetails" */ "@/views/organisation/OrganisationDetail")
    },
    {
      path: "/configuration",
      component: Configuration,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: "",
          name: "Configuration",
          redirect: { name: "SurveyTemplates" }
        },
        {
          path: "surveytemplates/",
          name: "SurveyTemplates",
          meta: {
            title: "Survey Templates"
          },
          component: () =>
            import(/* webpackChunkName: "SurveyTemplates" */ "@/views/configuration/surveyTemplates/")
        },
      ]
    }
  ]
});

// Protected routes handling
router.beforeEach(async (to, from, next) => {
  // Update page title
  document.title = to.meta.title || 'Wespond';
  // Logic to check auth state
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page
    let authState = localStorage.auth;

    if (authState == "false") {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});
export default router;