<template>
  <div>
    <el-container>
      <TheNavigation />
      <el-container style="margin-left: 200px;">
        <div class="contentContainer">
          <TheHeader title="Dashboard" />
          <el-main class="m-t-1">
            <slot />
          </el-main>
        </div>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import TheHeader from "@/components/Header";
import TheNavigation from "@/components/Navigation";

export default {
  components: {
    TheHeader,
    TheNavigation
  }
};
</script>


<style lang="scss">
.contentWrapper {
  margin-left: 200px;
}
</style>
