var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-badge", {
        staticClass: "item",
        attrs: { value: "1/2", type: "primary" }
      }),
      _c("h3", { staticStyle: { display: "inline", "margin-left": "5px" } }, [
        _vm._v("Basisinformationen")
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form },
          nativeOn: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.nextStep("form")
            }
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "m-t-2",
              attrs: {
                prop: "name",
                rules: {
                  required: true,
                  message: "Bitte Namen der Organisation eingeben",
                  trigger: "blur"
                }
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Name der Organisation" },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "m-t-2", attrs: { prop: "slug" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Slug (optional)" },
                model: {
                  value: _vm.form.slug,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "slug", $$v)
                  },
                  expression: "form.slug"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("Abbrechen")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.nextStep("form")
                }
              }
            },
            [_vm._v("Weiter")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }