var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-aside",
    { attrs: { width: "200px" } },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "default-active": _vm.activeLink,
            collapse: false,
            router: true,
            "text-color": "#eee",
            "active-text-color": "#fff",
            "background-color": "#122c51"
          }
        },
        [
          _c("img", {
            staticClass: "iconTop",
            attrs: { src: require("../assets/icon_white.png"), width: "35px" }
          }),
          _c(
            "div",
            { staticClass: "menuItemWrapper" },
            [
              _c(
                "el-menu-item",
                {
                  attrs: { index: "dashboard" },
                  on: {
                    click: function($event) {
                      _vm.$router
                        .push({ name: "Dashboard" })
                        .catch(function(err) {})
                    }
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-user" }),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Organisationen")
                  ])
                ]
              ),
              _c(
                "el-menu-item",
                {
                  attrs: { index: "configuration" },
                  on: {
                    click: function($event) {
                      _vm.$router
                        .push({ name: "Configuration" })
                        .catch(function(err) {})
                    }
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-user" }),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v("Configuration")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "collapseButton",
              attrs: { trigger: "click" },
              on: { command: _vm.handleCommand }
            },
            [
              _c("el-avatar", {
                staticClass: "el-dropdown-link",
                attrs: {
                  src:
                    "https://www.xing.com/image/7_c_b_bcb2f14ee_32712290_1/benedikt-b%C3%B6ringer-foto.1024x1024.jpg"
                }
              }),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [_vm._v("Einstellungen")]),
                  _c(
                    "el-dropdown-item",
                    { attrs: { command: "logout", divided: "" } },
                    [_vm._v("Ausloggen")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("div")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }