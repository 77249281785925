<template>
  <div>
    <!-- Header -->
    <el-badge value="1/2" class="item" type="primary" />
    <h3 style="display: inline;margin-left: 5px;">Basisinformationen</h3>

    <!-- Form -->
    <el-form :model="form" @keyup.enter.native="nextStep('form')" ref="form">
      <el-form-item
        prop="name"
        class="m-t-2"
        :rules="{ required: true, message: 'Bitte Namen der Organisation eingeben', trigger: 'blur'}"
      >
        <el-input v-model="form.name" placeholder="Name der Organisation"></el-input>
      </el-form-item>
      <el-form-item prop="slug" class="m-t-2">
        <el-input v-model="form.slug" placeholder="Slug (optional)"></el-input>
      </el-form-item>
    </el-form>

    <!-- Footer -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancel')">Abbrechen</el-button>
      <el-button type="primary" @click="nextStep('form')">Weiter</el-button>
    </span>
  </div>
</template>

<script>
export default {
  props: { data: Object },
  data() {
    return {
      form: {
        name: "",
        slug: ""
      }
    };
  },
  methods: {
    nextStep(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("nextStep", this.form);
        } else {
          console.log("error submit!");
        }
      });
    }
  }
};
</script>