import { db, functions } from "@/main";

const state = {
    // Save all states as list-object
    list: {},
    organisationMembers: {}
};

const getters = {};

const mutations = {
    SET_ORGANISATION_LIST(state, data) {
        state.list = data;
    },
    SET_ORGANISATION(state, data) {
        state.list[data.id] = data.data;
    },
    SET_MEMBER_LIST(state, data) {
        state.organisationMembers = data;
    }
};

// Actual actions
const actions = {

    // Update the list of all available organisations 
    async updateOrganisationList(context) {
        let rootRef = db.collection('root').doc('organisationOverview');
        let rootDoc = await rootRef.get();
        let root = rootDoc.data();
        context.commit("SET_ORGANISATION_LIST", root.organisations);
    },

    // Get information about an selected organisation
    async getOrganisation(context, payload) {
        // Retrieve organisation based on payload
        let ref = db.collection("organisations").doc(payload);
        let doc = await ref.get();
        let data = doc.data();
        // Commit action
        context.commit("SET_ORGANISATION", { data, id: payload });
    },

    // Get all members (incl. admins) of an selectet organisation
    async updateMemberList(context, orgId) {
        try {
            if (orgId == false) {
                context.commit("SET_MEMBER_LIST", {});
            }
            else {
                let ref = db.collection('organisations/' + orgId + '/overviews').doc('memberOverview');
                let doc = await ref.get();
                let data = doc.data();
                context.commit("SET_MEMBER_LIST", data.members);
            }
            return true;
        } catch (e) {
            console.log(e.code);
            console.log(e.message);
            return false;
        }
    },

    // Delete an selected organisation
    async deleteOrganisation(context, payload) {
        try {
            // Call cloud function
            let deleteOrganisation = functions.httpsCallable("organisationDelete");
            let response = await deleteOrganisation(payload);
            // Update data
            await context.dispatch("updateOrganisationList");
            return response;
        } catch (e) {
            console.log(e.code);
            console.log(e.message);
            return e.message;
        }
    },

    // Create a new Organisation
    async createOrganisation(context, payload) {
        try {
            // Call cloud function
            let createOrganisation = functions.httpsCallable("organisationCreate");
            let res = await createOrganisation(payload.data);

            // Update data
            await context.dispatch("updateOrganisationList");

            return res.data;
        } catch (e) {
            console.log(e.code);
            console.log(e.message);
            return e.message;
        }
    },

    async updateConfig(context, payload) {
        try {
            let organisationUpdatedConfig = functions.httpsCallable("organisationUpdateConfig");
            await organisationUpdatedConfig(payload);

            await context.dispatch("getOrganisation", payload.organisationId);
        } catch (e) {
            console.log(e.code);
            console.log(e.message);
            return e.message;
        }
    },
    async updateModuleConfig(context, payload) {
        try {
            let organisationUpdateModuleConfig = functions.httpsCallable("organisationUpdateModuleConfig");
            console.log(payload)
            let response = await organisationUpdateModuleConfig(payload);
            await context.dispatch("getOrganisation", payload.organisationId);
            return response.data;
        } catch (e) {
            console.log(e.code);
            console.log(e.message);
            return e.message;
        }
    }
};

export default {
    state,
    mutations,
    getters,
    actions
};
