var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("TheNavigation"),
          _c("el-container", { staticStyle: { "margin-left": "200px" } }, [
            _c(
              "div",
              { staticClass: "contentContainer" },
              [
                _c("TheHeader", { attrs: { title: "Dashboard" } }),
                _c("el-main", { staticClass: "m-t-1" }, [_vm._t("default")], 2)
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }