<template>
  <div>
    <!-- Header -->
    <el-badge value="2/2" class="item" type="primary" />
    <h3 style="display: inline;margin-left: 5px;">Organisations-Administrator hinzufügen</h3>

    <!-- Form -->
    <el-form :model="form" @keyup.enter.native="nextStep('form')" ref="form">
      <el-form-item 
        prop="fname" 
        class="m-t-2"
        :rules="{ required: true, message: 'Bitte Vornamen eingeben', trigger: 'blur'}">
        <el-input v-model="form.fname" placeholder="Vorname"></el-input>
      </el-form-item>

       <el-form-item 
        prop="lname" 
        class="m-t-2"
        :rules="{ required: true, message: 'Bitte Nachnamen eingeben', trigger: 'blur'}">
        <el-input v-model="form.lname" placeholder="Nachname"></el-input>
      </el-form-item>

      <el-form-item 
        prop="email" 
        :rules="[
          { required: true, message: 'Bitte E-Mail Adresse eingeben', trigger: 'blur' },
          { type: 'email', message: 'Korrektes Format: mail@domain.de', trigger: 'blur' }
        ]">
        <el-input type="email" placeholder="E-Mail" v-model="form.email" />
      </el-form-item>
    </el-form>

    <!-- Footer -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('back')">Zurück</el-button>
      <el-button type="primary" @click="nextStep('form')" :loading="isLoading">Organisation erstellen </el-button>
    </span>
  </div>
</template>

<script>
export default {
  props: { data: Object, isLoading: Boolean },
  data() {
    return {
      autoCompleteState: "",
      form: {
        fname: "",
        lname: "",
        email: ""
      }
    };
  },
  
  methods: {
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("nextStep", this.form);
        } else {
          console.log('error submit!');
        }
      });
    },
  }
};
</script>