// ./store/modules/account.js

// initial state
const state = {
  initialLoad: false
};

const getters = {
  getInitialLoadingState: state => {
    return state.initialLoad;
  }
};

const mutations = {
  setInitialLoadingState(state, initialLoadingState) {
    state.initialLoad = initialLoadingState;
  }
};

const actions = {
  updateInitialLoadingState(context, loadingState) {
    // commit action
    context.commit("setInitialLoadingState", loadingState);
  }
};

export default {
  state,
  mutations,
  getters,
  actions
};
