let dev = {
    apiKey: "AIzaSyC_VeCsiNbgppc3UrjOl7cWrdrWsRLPZt4",
    authDomain: "wespond-dev.firebaseapp.com",
    databaseURL: "https://wespond-dev.firebaseio.com",
    projectId: "wespond-dev",
    storageBucket: "wespond-dev.appspot.com",
    messagingSenderId: "302993029104",
    appId: "1:302993029104:web:f5a1d5b41334d76fc24f35",
    measurementId: "G-KNQCBZZR49"
}

let staging = {
    apiKey: "AIzaSyBSAnh5lqYriyGXbPuQYFwiusfrMc9aGvY",
    authDomain: "wespond-staging.firebaseapp.com",
    databaseURL: "https://wespond-staging.firebaseio.com",
    projectId: "wespond-staging",
    storageBucket: "wespond-staging.appspot.com",
    messagingSenderId: "556285046343",
    appId: "1:556285046343:web:6083233eba833e7e008b62",
    measurementId: "G-ZBW2KQW6SR"
}

let production = {
    apiKey: "AIzaSyDWcDrApRUzGsNtNWu_Wv0gdrjEHT_4OiU",
    authDomain: "wespond-production.firebaseapp.com",
    databaseURL: "https://wespond-production.firebaseio.com",
    projectId: "wespond-production",
    storageBucket: "wespond-production.appspot.com",
    messagingSenderId: "250877396394",
    appId: "1:250877396394:web:aebf863dc66ae8348b97d3",
    measurementId: "G-QRGB919EQ4"
}

export default { dev, staging, production }