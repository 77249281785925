<template>
  <div>
    <!-- Visible as button in mother-component -->
    <el-button
      size="default"
      type="primary"
      @click="dialogVisible = true"
      icon="el-icon-plus"
    >Organisation erstellen</el-button>
    <el-dialog class="ta-l" title="Organisation erstellen" :visible.sync="dialogVisible">
      <!-- Process-bar -->
      <el-steps :active="activeStep" align-center class="m-b-3" finish-status="success">
        <el-step title="Basics"></el-step>
        <el-step title="Admin"></el-step>
      </el-steps>

      <!-- First step -->
      <div v-if="activeStep == 0">
        <AddOrganisationBasicInformation
          @nextStep="nextStep($event)"
          @cancel="cancelCreation()"
          :data="{
              name: data.name
          }"
        />
      </div>

      <!-- Second step -->
      <div v-if="activeStep == 1">
        <AddOrganisationAdmin
          @nextStep="nextStep"
          @back="activeStep--"
          @updateData="updateData($event)"
          :currentMembers="currentMemberList"
          :data="{admin: data.admin}"
          :isLoading="isLoading"
        />
      </div>

      <div v-if="activeStep == 2">
        <p>Admin Passwort: {{adminPassword}}</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AddOrganisationBasicInformation from "./AddOrganisationBasicInformation";
import AddOrganisationAdmin from "./AddOrganisationAdmin";

export default {
  components: { AddOrganisationBasicInformation, AddOrganisationAdmin },
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      adminPassword: "",
      activeStep: 0, // Counter for steps
      currentMemberList: Array,
      data: {
        // Dummy data
        name: "",
        slug: "",
        admin: {
          fname: "",
          lname: "",
          email: ""
        },
        config: ["surveys", "feedback-box"],
        moduleConfig: {
          feedbackBox: {
            allowPeerFeedback: true,
            allowPeerTeamFeedback: true,
            allowFeedbackToOrgAdmin: true,
            allowPublicFeedback: false,
            allowAnonymousFeedback: true,
            allowDemonstratedValues: false,
            demonstratedValues: ["Teamwork", "Ownership", "Creativity"],
            allowReactionsToPublicFeedback: false,
            enableAutoCheckins: false,
            enableFeedbackBoard: false
          }
        },
        isActive: true
      }
    };
  },
  methods: {
    // Process depends on activeStep counter
    async nextStep(stepData) {
      if (this.activeStep == 1) {
        // Ready & submit
        this.isLoading = true;

        // Adjust admin/member arrays for function
        let data = {};
        data.name = this.data.name;
        data.organisationSlug = this.data.slug;
        data.admin = stepData;
        data.config = this.data.config;
        data.isActive = true;
        data.moduleConfig = this.data.moduleConfig;
        let res = await this.$store.dispatch("createOrganisation", {
          data: data
        });

        if (res.code !== 200) {
          this.$notify({
            title: "Fehler",
            message: "Die Organisation konnte nicht erstellt werden",
            type: "error"
          });
        }

        this.adminPassword = res.adminPassword;
        this.activeStep++;
      } else if (this.activeStep == 2) {
        // Done, reset form
        this.activeStep = 0;
        this.dialogVisible = false;
        this.data = { name: "", admins: {}, config: [], isActive: true };
      } else {
        // Go to next step
        this.data = { ...this.data, ...stepData };
        this.activeStep++;
        stepData = {};
      }
      this.isLoading = false;
    },
    updateData(data) {
      // Preparing data
      this.data = { ...this.data, ...data.data };
      this.currentMemberList = data.currentMembers;
    },
    cancelCreation() {
      // Reset by cancel
      this.dialogVisible = false;
      this.activeStep = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .dialog-footer {
  display: block;
  text-align: right;
}
</style>