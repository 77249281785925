// ./store/surveyTemplates.js
import { db, functions } from "@/main";

// initial state
const state = {
    templates: {}
};

const getters = {};

const mutations = {
    setInitialLoadingState(state, initialLoadingState) {
        state.initialLoad = initialLoadingState;
    }
};

const actions = {
    async createSurveyTemplate(context, payload) {
        console.log(payload);
        let templateCreate = functions.httpsCallable("surveys-templateCreate");
        let response = await templateCreate(payload);

        return response;
    }
};

export default {
    state,
    mutations,
    getters,
    actions
};
